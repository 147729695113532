export class HttpBaseResponse<T = void> {
  public constructor(status: HttpBaseResponseStatus, data: T, errors: HttpBaseResponseError[]) {
    this.status = status;
    this.data = data;
    this.errors = errors;
  }
  status: HttpBaseResponseStatus;
  data: T;
  dataVersion: string;
  errors: HttpBaseResponseError[];
}

export enum HttpBaseResponseStatus {
  OK = 1,
  Error = 2,
}

export class HttpBaseResponseError {
  errorCode: OneApiResponseErrorCode;
  serverity: HttpBaseResponseErrorSeverity;
  errorMessage: string;
}

export enum OneApiResponseErrorCode {
  invalid = 400,
  notFound = 404,
  gatewayTimeOut = 504,
  versionMismatch = 400000001,
  articleNumberAlreadyUsed = 404000001,
  noTenantAllowed = 400000004,
  entityAlreadyExists = 404000005,
  entityNameAlreadyExists = 404000007,
  validatorSaveError = 404000009,
  maxFileUploadSize = 405000002,
  oneIsUnderMaintenance = 40400503,
  placeholderTypeUndefinedPatternAttribute = 404000022,
  lengthExceeded = 404000023,
  required = 404000026,
  idpAssetInvalidFilename = 408000028,
  invalidSetup = 404000006,
  unspecifiedError = 404000012,
}

export enum HttpBaseResponseErrorSeverity {
  Warning = 1,
  Error = 2,
  Information = 3,
}
